import { Directive, Input, OnChanges, OnInit, ViewContainerRef, TemplateRef } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ListFilter } from '@parashift/shared/models';
import { CurrentTenantRegister } from '@parashift/shared/services';

@Directive({
  selector: '[resultSwitch]',
  standalone: true
})
export class ResultSwitchDirective implements OnChanges, OnInit {
  @Input() resultSwitch = 0;
  @Input() resultSwitchListFilter: ListFilter;
  @Input() resultSwitchTemplatePart: 'result' | 'empty-result';
  private tenant_id;

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<Element>,
    private route: ActivatedRoute,
    private currentTenantRegister: CurrentTenantRegister
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.tenant_id = params.get('tenant_id');
    });
  }

  ngOnChanges() {
    this.setTemplatePart();
  }

  setTemplatePart() {
    const current_tenant = this.currentTenantRegister.tenant;
    this.viewContainer.clear();

    if (!this.resultSwitch && (this.resultSwitchListFilter.filterUntouched !== true && this.resultSwitchListFilter.filterUntouched !== false)) {
      this.viewContainer.clear();
      return;
    }

    if (this.tenant_id && current_tenant && current_tenant.trial) {
      if (!this.resultSwitch || this.resultSwitch === 0) {
        if (this.resultSwitchTemplatePart === 'result' && this.resultSwitchListFilter.filterUntouched) {
          this.viewContainer.clear();
          return;
        }

        if (this.resultSwitchTemplatePart === 'empty-result' && !this.resultSwitchListFilter.filterUntouched) {
          this.viewContainer.clear();
          return;
        }
      } else if (this.resultSwitchTemplatePart === 'empty-result') {
        this.viewContainer.clear();
        return;
      }
    } else if (this.resultSwitchTemplatePart === 'empty-result') {
      this.viewContainer.clear();
      return;
    }

    this.viewContainer.createEmbeddedView(this.templateRef);
  }
}
