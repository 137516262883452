<div *ngIf="teaser; else no_teaser" class="empty-result-description-component card">
  <div class="card-body">
    <div class="row">
      <div *ngIf="imgPath" class="col-sm-6"><img [src]="imgPath" class="img-fluid"></div>
      <div [ngClass]="imgPath ? 'col-sm-6' : 'col-sm-12'">
        <div class="alert alert-warning mb-4">{{ emptyResult }}</div>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>

<ng-template #no_teaser>
  <div class="alert alert-warning">{{ emptyResult }}</div>
</ng-template>

