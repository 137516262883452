import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';
import { translateModelTitles } from '@parashift/shared/utils';

@Directive({
  selector: '[appListResultCounter]',
  standalone: true
})
export class ListResultCounterDirective implements OnChanges {
  @Input() pageNumber: number;
  @Input() pageSize: number;
  @Input() totalCount: number;
  @Input() modelName = translateModelTitles('record', 0);
  el: ElementRef;

  private strong: ElementRef;
  private span: ElementRef;

  constructor(el: ElementRef, private renderer: Renderer2) {
    this.el = el;
  }

  ngOnChanges() {
    this.createResult();
  }

  createResult() {
    this.renderer.addClass(this.el.nativeElement, 'list-result-counter');

    if (this.span) {
      this.renderer.removeChild(this.el.nativeElement, this.span);
    }

    if (this.strong) {
      this.renderer.removeChild(this.el.nativeElement, this.strong);
    }

    const countStart = this.pageSize * (this.pageNumber - 1) + 1;
    const countEnd = this.pageSize * this.pageNumber;
    const result = `${ countStart } - ${ countEnd > this.totalCount ? this.totalCount : countEnd }`;
    this.strong = this.renderer.createElement('strong');
    const resultText = this.renderer.createText(result);
    this.renderer.appendChild(this.strong, resultText);

    const totalCount = ` of ${this.totalCount}`;
    this.span = this.renderer.createElement('span');
    const text = this.renderer.createText(totalCount);
    this.renderer.appendChild(this.span, text);

    this.renderer.appendChild(this.el.nativeElement, this.strong);
    this.renderer.appendChild(this.el.nativeElement, this.span);
  }
}
