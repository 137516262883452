import { Component, Input, OnInit } from '@angular/core';
import { AvailableModels, translateModelTitles } from '@parashift/shared/utils';
import { NgIf, NgClass } from '@angular/common';

@Component({
  selector: 'empty-result-description',
  templateUrl: './empty-result-description.component.html',
  standalone: true,
  imports: [NgIf, NgClass]
})
export class EmptyResultDescriptionComponent implements OnInit {
  @Input() teaser = false;
  @Input() emptyResult: string;
  @Input() model: AvailableModels;
  @Input() imgPath: string;

  ngOnInit() {
    if (this.model) {
      let translatedModel = translateModelTitles(this.model, 0);
      // if (translatedModel && this.translate.currentLang !== 'de') {
      translatedModel = translatedModel.toLowerCase();
      // }
      this.emptyResult = $localize `:@@common.no_result_with_model:There are no ${translatedModel}:modelTitle:`;
    } else {
      this.emptyResult = $localize `:@@common.:No result`;
    }
  }
}
